import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import { FormattedMessage } from 'react-intl';
import { mapSkuToName } from './common/mapProductSkuToProductName';
import { UpsellingProductFeatures } from './UpsellingProductFeatures';
import classNames from 'classnames';
import { getLearnMoreTrackingAttributes } from './common/googleTagManagerAttributes';
import { BuyButton } from './BuyButton';

interface Props {
    productId: string;
    level?: string;
    resourceId?: string;
    noBorder?: boolean;
    shownLink: 'learnMore' | 'bookNow';
    showResourceNotBookable?: boolean;
}

const Upselling = ({ productId, level, resourceId, noBorder, shownLink, showResourceNotBookable }: Props) => {
    const panelClasses = classNames(
        'display-flex flex-column align-items-center margin-0 panel panel-default padding-5pct',
        noBorder ? 'border-none rounded-none' : ''
    );

    return (
        <div>
            <div className={panelClasses}>
                {showResourceNotBookable && (
                    <div className='alert alert-info margin-bottom-20 max-width-500'>
                        <div className='display-flex gap-10'>
                            <span className='text-color-info text-size-h4 rioglyph rioglyph rioglyph-info-sign' />
                            <div>
                                <FormattedMessage id={'intl-msg:upselling.resourceNotBookable'} />
                            </div>
                        </div>
                    </div>
                )}
                <div className='text-size-h2 margin-bottom-15'>
                    <span className='rioglyph text-color-primary rioglyph-stars text-size-300pct' />
                </div>
                <div className='text-size-h3 text-size-h2-sm line-height-125rel text-color-dark text-bold max-width-500 text-center'>
                    <FormattedMessage id={'intl-msg:upselling.premiumFeature'} />
                </div>
                <div className='text-color-dark margin-top-15 line-height-125rel display-flex flex-column align-items-center max-width-500 width-100pct'>
                    {shownLink === 'bookNow' && (
                        <div
                            className='text-medium margin-bottom-20 text-size-14 text-size-18-sm text-center margin-x-10pct-ls'
                            id='headline'
                        >
                            <FormattedMessage id={'intl-msg:upselling.book'} />{' '}
                            <span className='text-bold text-color-primary margin-x-2'>{mapSkuToName(productId)}</span>
                            &nbsp;
                            <FormattedMessage id={'intl-msg:upselling.moreSpecialFeatures'} />
                        </div>
                    )}
                    <UpsellingProductFeatures productId={productId} />
                </div>
                <div className='max-width-500 width-100pct margin-top-25'>
                    {shownLink === 'learnMore' ? (
                        <div className='text-center' {...getLearnMoreTrackingAttributes('visibility')}>
                            <a
                                className='btn btn-primary'
                                href={`https://marketplace.rio.cloud/product/${productId}`}
                                target='_blank'
                                rel='noreferrer'
                                {...getLearnMoreTrackingAttributes('click')}
                            >
                                <span className='rioglyph rioglyph-shopping-cart' />
                                <FormattedMessage id={'intl-msg:upselling.learnMore'} />
                            </a>
                        </div>
                    ) : (
                        <BuyButton productId={productId} level={level} resourceId={resourceId} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Upselling;
