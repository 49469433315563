import * as t from 'io-ts';

export const ApiResourceActivationCodec = t.intersection([
    t.type({
        resource_id: t.string,
        resource_type: t.string,
        bookable: t.boolean,
        activation_state: t.string,
    }),
    t.partial({
        resource_name: t.string,
    }),
]);

export const ApiBookableResourcesCodec = t.type({
    resources: t.array(ApiResourceActivationCodec),
});
