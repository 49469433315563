import { decodeJson } from '../api';
import { FreeTrialResponseDecoder } from './freeTrial.types';
import type { FreeTrialMappedResponse, FreeTrialResponse } from '../../freeTrial/redux/types';

export const mapApiFreeTrialStatus = (apiFreeTrial: FreeTrialResponse): FreeTrialMappedResponse => {
    const json = decodeJson<FreeTrialResponse>(FreeTrialResponseDecoder);

    const decoded = json(apiFreeTrial);

    return {
        ...decoded,
        endsAt: decoded.ends_at ? decoded.ends_at : undefined,
        eligible: decoded.status === 'eligible',
    };
};
