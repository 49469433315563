import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import manPointingUp from './images/man_pointing_up.webp';
import { FormattedMessage } from 'react-intl';
import { FreeTrialButton } from './FreeTrialButton';
import { mapSkuToName } from '../common/mapProductSkuToProductName';
import classNames from 'classnames';

interface Props {
    productId: string;
    noBorder?: boolean;
}
const FreeTrial = ({ productId, noBorder }: Props) => {
    const panelClasses = classNames(
        'display-flex flex-column align-items-center margin-0 panel panel-default padding-x-5pct bg-primary overflow-hidden position-relative',
        noBorder ? 'border-none rounded-none' : ''
    );

    return (
        <div className={panelClasses}>
            <div className='width-90pct rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-40 blur-50' />
            <div className='display-flex align-items-center flex-column position-relative z-index-1'>
                <div className='margin-bottom-25 margin-top-15pct text-center'>
                    <div className='text-size-h2 text-size-h1-sm text-color-white text-medium line-height-h1 margin-bottom-3pct'>
                        <FormattedMessage id={'intl-msg:upselling.premiumFeature'} />
                    </div>
                    <div className='text-size-16 text-size-18-sm line-height-125rel' id='freeTrialHeadline'>
                        <FormattedMessage id={'intl-msg:upselling.try'} /> &nbsp;
                        <span className='text-bold text-color-secondary margin-x-2'>{mapSkuToName(productId)}</span>{' '}
                        &nbsp;
                        <FormattedMessage id={'intl-msg:upselling.moreServices'} />
                    </div>
                </div>
                <FreeTrialButton />
            </div>
            <img
                className='img-responsive margin-x-auto margin-top-25 position-relative max-width-250 max-width-300-sm'
                alt='Man pointing up'
                src={manPointingUp}
            />
        </div>
    );
};

export default FreeTrial;
