import type { FormatDateOptions } from 'react-intl';
import { addMonths } from 'date-fns';

export function getNowPlusMonths(numberOfMonths: number): Date {
    return addMonths(new Date(), numberOfMonths);
}

export function getDateFromIsoString(date: string | undefined): Date | undefined {
    return date ? new Date(date) : undefined;
}

export function getCurrentDate(): Date {
    return new Date();
}

export function addOffsetToDate(date: Date, offset: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() + offset);
    return newDate;
}

export const DATE_FORMAT: FormatDateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
};
