import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import { FormattedMessage } from 'react-intl';
import { mapSkuToName } from './common/mapProductSkuToProductName';

interface Props {
    productId: string;
}

const PendingActivationMessage = ({ productId }: Props) => {
    const productName = mapSkuToName(productId);
    return (
        <div>
            <div className='alert alert-info'>
                <div className='display-flex'>
                    <span className='text-color-info text-size-h4 margin-right-10 rioglyph rioglyph-hour-glass' />
                    <div>
                        <strong className='text-size-16'>
                            <FormattedMessage id={'intl-msg:upselling.activation.pending'} />
                        </strong>
                        <div className='last-child-margin-bottom-0'>
                            <p>
                                <FormattedMessage
                                    id={'intl-msg:upselling.activation.pending.info.1'}
                                    values={{ product: <b>{productName}</b> }}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={'intl-msg:upselling.activation.pending.info.2'}
                                    // @ts-ignore
                                    values={{ bold: (chunks: string[]) => <b>{chunks}</b> }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PendingActivationMessage;
