import { configureStore as configureStoreToolkit } from '@reduxjs/toolkit';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../features/app/appSlice';
import { marketplaceApi } from '../../features/app/api/marketplaceApi';

export const configureStore = () =>
    configureStoreToolkit({
        reducer: {
            config: configReducer,
            lang: langReducer,
            app: appReducer,
            login: loginReducer,
            tokenHandling: tokenReducer,
            [marketplaceApi.reducerPath]: marketplaceApi.reducer,
        },
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(marketplaceApi.middleware),
    });

export const store = configureStore();

export type Store = typeof store;

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
