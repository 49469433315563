import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import { useGetFreeTrialStatusQuery, useLazyGetBookablesQuery } from './api/marketplaceApi';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';
import FreeTrial from './freeTrial/FreeTrial';
import Upselling from './Upselling';
import type { FreeTrialMappedResponse as FreeTrialData } from './freeTrial/redux/types';
import { ActivationStatus, type Bookable } from './bookable/redux/types';
import UnauthorizedError from './UnauthorizedError';
import PendingActivationMessage from './PendingActivationMessage';
import { useEffect } from 'react';
import { BuyButton } from './BuyButton';

const UpsellingWrapper = () => {
    const searchParams = new URLSearchParams(location.search);

    const sku = searchParams.get('productId')?.toLowerCase(); // rio-sku00000058
    const level = searchParams.get('level') ?? '10'; // 10
    const resourceId = searchParams.get('resourceId') ?? undefined;
    const noBorder = searchParams.get('noBorder') === 'true';
    const onlyBuyButton = searchParams.get('onlyBuyButton') === 'true';

    if (!sku) {
        return <></>;
    }

    const freeTrialQuery = useGetFreeTrialStatusQuery();
    const [bookablesQueryTrigger, bookablesQuery] = useLazyGetBookablesQuery();

    useEffect(() => {
        if (resourceId) {
            bookablesQueryTrigger({ sku, level, resourceId });
        }
    }, []);

    // Redirect to Geo L if current level is not bookable
    useEffect(() => {
        if (sku === 'rio-sku00000047' && level !== '34' && bookablesQuery.data?.status === false) {
            if (window.location.href.includes('level=')) {
                window.location.replace(window.location.href.replace(/level=[0-9a-z-]+/, 'level=34'));
            } else {
                window.location.replace(`${window.location.href}&level=34`);
            }
        }
    }, [sku, level, bookablesQuery]);

    if (freeTrialQuery.isLoading || bookablesQuery.isLoading) {
        return (
            <div className={onlyBuyButton ? 'margin-top-5' : 'margin-top-5pct'}>
                <div className='spinnerInfoBox display-flex justify-content-center align-items-center height-100pct'>
                    <div className='spinner'>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        );
    }

    if (
        (freeTrialQuery.isError && isUnauthorizedError(freeTrialQuery.error)) ||
        (bookablesQuery.isError && isUnauthorizedError(bookablesQuery.error))
    ) {
        return (
            <div className='container-fluid fluid-small'>
                <UnauthorizedError />
            </div>
        );
    }

    return showFreeTrialOrUpselling({
        sku,
        level,
        resourceId,
        noBorder,
        onlyBuyButton,
        freeTrialData: freeTrialQuery?.data,
        bookableData: bookablesQuery?.data,
    });
};

const isUnauthorizedError = (error: FetchBaseQueryError | SerializedError) =>
    error && ((error as { status?: number }).status === 401 || (error as { status?: number }).status === 403);

function showFreeTrialOrUpselling({
    sku,
    level,
    resourceId,
    noBorder,
    onlyBuyButton,
    freeTrialData,
    bookableData,
}: {
    sku: string;
    level: string;
    resourceId?: string;
    noBorder: boolean;
    onlyBuyButton: boolean;
    freeTrialData?: FreeTrialData;
    bookableData?: Bookable;
}) {
    if (onlyBuyButton) {
        if (resourceId && bookableData?.status) {
            return <BuyButton productId={sku} level={level} resourceId={resourceId} />;
        }
        return <></>;
    }
    if (freeTrialData?.eligible) {
        return <FreeTrial productId={sku} noBorder={noBorder} />;
    }
    if (resourceId && bookableData?.activation === ActivationStatus.PENDING_ACTIVATION) {
        return <PendingActivationMessage productId={sku} />;
    }
    if (resourceId && bookableData?.activation === ActivationStatus.ACTIVE) {
        return <></>;
    }
    if (resourceId && bookableData?.status) {
        return (
            <Upselling productId={sku} level={level} resourceId={resourceId} noBorder={noBorder} shownLink='bookNow' />
        );
    }
    return (
        <Upselling
            productId={sku}
            level={level}
            resourceId={resourceId}
            noBorder={noBorder}
            shownLink='learnMore'
            showResourceNotBookable={Boolean(resourceId && bookableData?.status === false)}
        />
    );
}

export default UpsellingWrapper;
