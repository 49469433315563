import packageJson from '../package.json';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        MARKETPLACE_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
    serviceVersion: string;
}

const getBoolEnvValue = (envValue: string): boolean => {
    return import.meta.env[envValue] !== 'undefined' && import.meta.env[envValue] === 'true';
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        MARKETPLACE_SERVICE: import.meta.env.VITE_MARKETPLACE_BACKEND_URL,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '45d5cff3-951e-4d5a-96c6-2bf44533ff3c',
        oauthScope: ['openid', 'profile', 'email', 'marketplace.read'],
        mockAuthorization: getBoolEnvValue('VITE_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('VITE_LOGIN_PREVENT_REDIRECT'),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'upselling',
    serviceVersion: packageJson.version,
};
