import type * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';

export const decodeJson =
    <T>(decoder: t.Decoder<unknown, T>) =>
    (json: unknown): T => {
        const validationResult = decoder.decode(json);

        if (isRight(validationResult)) {
            return validationResult.right;
        }
        throw new Error(`Error during parsing response: ${PathReporter.report(validationResult)}`);
    };
